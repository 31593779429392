body::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}

body::-webkit-scrollbar-thumb{
  background-color: rgba(0,0, 0, 0.3);
}
.menu-horizontal {
  border-bottom-left-radius: 35% !important;
  border-bottom-right-radius: 0px !important;
  padding: 20px 20px 50px 20px;
  z-index: 1000;
  position: fixed;
  width: 100%;
  box-shadow: 0px 1px 4px #aaa;
  background-color: #fff;
  z-index: 1000;
}

.menu-horizontal .menu {
  max-height: 70px !important;
}

.logo-home {
  padding-left: 50px !important;
  padding-top: 15px !important;
  margin-top: 60px !important;
}

.logo-home img {
  border-radius: 50%;
  padding: 3px 21px;
  background-color: #fff;
  background: black;
  box-shadow: 1px 1px 3px rgba(0, 87, 19, 0.404);
  height: 100px;
}

.logo-dashboard {
  padding-left: 50px !important;
  padding-top: 15px !important;
  margin-top: 20px !important;
}

.logo-dashboard img {
  border-radius: 50%;
  padding: 2px 14px 1px 14px;
  background-color: #fff;
  background: linear-gradient(to right, rgb(204, 219, 207), rgb(255, 255, 255));
  box-shadow: 1px 1px 3px rgba(0, 87, 19, 0.404);
  height: 70px;
}

.logo-pagina-erro img {
  border-radius: 50%;
  padding: 3px 21px;
  background-color: #fff;
  background: linear-gradient(to right, rgb(204, 219, 207), rgb(255, 255, 255));
  box-shadow: 1px 1px 3px rgba(0, 87, 19, 0.404);
  height: 120px;
}

.menu-item {
  padding: 10px 15px !important;
  margin: -5px;
  transition: .3s ease;
  color: #394867;
}

.menu-item-drawer {
  font-size: 12pt !important;
}

.menu-item:hover {
  color: #26a69a;
}

.menu-link {
  text-transform: uppercase !important;
  word-spacing: 1pt;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none !important;
}

.menu-link-drawer {
  text-transform: uppercase;
  font-size: 11px;
  padding: 20px 10px;
  display: block;
  padding-left: 30px !important;
}

.menu-link-drawer:hover {
  background-color: rgba(0, 87, 19, 0.2);
}

.contato{
  border: 2px solid #394867;
  border-radius: 10px;
  padding: 8px !important;
}

.contato:hover{
  border: 2px solid #26a69a;
}

.corpo-home {
  padding: 21vh 40px 50px 40px;
  /* background: rgb(10, 14, 10); */
  /* background-image: linear-gradient(to right, rgb(7, 4, 6), #053256); */
  background-image: url("/assets/imagens/gamer-background.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  margin-top: -100px;
  position: relative;
  height: 103vh;
}
.corpo-home {
  text-align: center;
  /* margin-top: 160px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.corpo-home-fade {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.corpo-home a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #053256 !important;
  font-size: 13pt !important;
  background-color: #fff !important;
  background: linear-gradient(to right, rgb(204, 219, 207), rgb(255, 255, 255));
  border-radius: 60px;
  height: 50px;
  width: 50px;
  box-shadow: 1px 1px 3px rgba(0, 87, 19, 0.404);
  padding: 13.3px 8.5px 11.3px 11.35px;
  bottom: 50px;
  left: calc(50% - 21.9px);
  z-index: 100;
  animation: sobe-desce 1.2s infinite alternate ease-in-out;
}

@keyframes sobe-desce {
  from {
    top: 50px;
  }
  to {
    top: 100px;
  }
}

.corpo-home a:hover {
  transition: 0.3s;
  transform: scale(1.2);
}

.ui-img-home {
  text-align: right;
}

.ui-img-home img {
  padding: 60px;
  background: linear-gradient(
    to right,
    rgba(204, 219, 207, 0),
    rgba(255, 255, 255, 0)
  );
  max-height: 400px;
  padding: 10px;
  border-radius: 20px;
}

.ui-home-section-1 {
  padding-bottom: 100px !important;
}

.ui-home-section-1 h1 {
  font-size: 20pt;
  font-weight: 800 !important;
}

.ui-home-section-1 h3 {
  margin-top: 0px;
  font-size: 15pt;
  font-weight: 100 !important;
}

.ui-home-section-2 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ui-card-1 {
  margin: auto !important;
  margin-bottom: 15px !important;
  transition: 0.3s !important;
}

/* .ui-card-1::before {
  transition: all .3s !important;
  opacity: 0;
  background: rgba(4, 43, 23, 0.5);
  color: #FFF;
  font-size: 18pt;
  font-weight: 900 !important;
  position: absolute;
  height: 30%;
  width: 100%;
  z-index: 500;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 35%;
  left: 0%;
  border-radius: 4px;
  font-weight: 900;
  content: "Detalhes da partida" !important;
} */

.ui-card-1:hover {
  transform: scale(1.1);
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0px 2px 8px #aaa;
}

.ui-card-1:hover::before {
  height: 100%;
  width: 100%;
  left: 0%;
  bottom: 0%;
  opacity: 1;
}

.ui-card-1::before * {
  vertical-align: middle;
}

.ui-card-1-content {
  max-height: 119px !important;
  min-height: 119px !important;
  overflow: hidden;
  position: relative;
}

.ui-card-1-content a {
  position: absolute;
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 8px 24px;
  margin-top: 24px;
  left: 0px;
  bottom: 0px;
  background: #000;
  color: #fff;
  font-size: 0.9em;
}

.ui-card-1-content a:hover {
  opacity: 0.9;
}

.ui-card-1-content-2 {
  max-height: 78px !important;
  min-height: 78px !important;
  overflow: hidden;
}

.ui-card-1-header {
  font-size: 12pt !important;
  font-weight: 900 !important;
}

.ui-card-2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ui-card-2 img {
  max-height: 120px;
}

.ui-card-2 svg {
  max-height: 105px;
  fill: #fff !important;
}

.ui-card-3 {
  margin: auto !important;
}

.ui-card-2 p {
  padding: 20px 40px;
  font-size: 11pt;
}

.ui-home-section-3 {
  padding: 21vh 40px 50px 40px;
  background: rgb(10, 14, 10);
  /* height: 170vh; */
  /* background-image: linear-gradient(to right, rgb(7, 4, 6), #053256); */
  background-image: url("/assets/imagens/gamer_background.jpg");
  background-attachment: fixed;
  /* background-position: center; */
  background-repeat: space;
  /* background-position: center; */
  /* background-size: 100% 80vh; */
  background-size:cover;
  color: #fff;
  position:relative;
  background-clip:border-box;
}

.ui-home-section-3 h2 {
  text-align: center;
  fill: #fff !important;
  color: #fff !important;
}

.ui-home-section-3 h3 {
  text-align: center;
  font-weight: 100;
  margin-bottom: 70px;
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.ui-home-section-4 {
  padding-top: 80px;
  padding-bottom: 90px;
}

.ui-home-section-4 h2 {
  text-align: center;
}

.ui-home-section-4 h2 span {
  color: rgb(44, 32, 41);
}

.ui-div-section-4 {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.ui-div-section-parceiros {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;
}

.ui-div-section-parceiros-item {
  text-align: center;
  margin: 8px;
}

.ui-div-section-parceiros img {
  max-height: 100px;
  border-radius: 8px;
  filter: grayscale(100%);
  transition: 0.2s;
}

.ui-div-section-parceiros img:hover {
  filter: grayscale(0%);
}

.ui-home-section-vip {
  background-image: url("/assets/imagens/turmas-vip.jpg");
  /* background-image: linear-gradient(to right, rgb(255, 255, 255), #053256); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
}

.ui-home-section-vip svg {
  max-height: 200px;
  fill: #fff !important;
  margin-bottom: 20px;
}

.ui-home-section-vip h1,
.ui-home-section-vip h2 {
  text-align: center;
}

.ui-home-section-vip-offset {
  padding: 120px 20px;
  background-color: rgba(0, 0, 0, 0.4);
}

.ui-section-vip-sub {
  font-weight: 100 !important;
}

.ui-social-whatsapp {
  color: rgb(3, 3, 3);
  text-align: center;
  font-size: 50pt;
  display: inline-block;
}

.ui-social-whatsapp:hover {
  transform: scale(1.15) !important;
  transition: 0.3s;
  color: #053256;
}

.ui-form-contato {
  text-align: left !important;
  margin: auto !important;
  width: 80%;
}

.ui-erro-inline {
  color: rgb(211, 77, 77);
  font-size: 9pt;
  padding-top: 3px;
}
.form-container-all{
  padding: 10px;
}
.ui-form-contato input::placeholder,
.ui-form-contato textarea::placeholder {
  color: #616161 !important;
}

.ui-form-contato input,
.ui-form-contato textarea{
  border: 0 !important;
  border-bottom: 2px solid #9e9e9e !important;
  outline: none !important;
  transition: .2s ease-in-out !important;
  box-sizing: border-box !important;
}

.ui-form-contato-conatiner{
  position: relative !important;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui-form-contato-input, .ui-form-contato-label{
  font-size: 1rem;  
}

.ui-form-contato-label{
  position: absolute !important;
  top: 4px !important;
  left: 0 !important;
  right: 0 !important;
  color: #616161 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 1rem !important;
  cursor: text !important;
  transition: .2s ease-in-out !important;
  box-sizing: border-box !important;
}

.ui-form-contato-input:valid  ,.ui-form-contato input:focus, .ui-form-contato textarea:focus{
  border-bottom: 2px solid #26a69a !important;
}

.ui-form-contato-input:valid + .ui-form-contato-label,
.ui-form-contato-input:focus + .ui-form-contato-label{
  color: #26a69a !important;
  font-size: .8rem !important;
  top: -30px !important;
  pointer-events: none !important;
}

.ui-form-contato-input:focus + label,
.ui-form-contato-input:not(:placeholder-shown) + label{
  font-size: 13px;
  margin-top: 0;
  color: #053256;
}

.ui-div-section-4 h3{
  margin-bottom: 30px !important;
}

.ui-label-color-1 {
  background: rgb(0, 119, 60) !important;
  color: #fff !important;
}

.ui-label-color-2 {
  background: rgb(0, 119, 60) !important;
  color: #fff !important;
}

.ui-label:hover {
  transition: 0.3s !important;
  opacity: 0.85 !important;
}

/* Login */

.ui-pagina-login {
  padding-top: 50px;
}

.ui-pagina-login .ui-login-logo {
  text-align: center;
}

.ui-pagina-login .ui-login-logo img {
  width: 85%;
  max-width: 220px;
}

/* Partida */

.ui-preview-foto {
  width: 90vw;
  height: 55.5555vw;
  max-width: 450px;
  max-height: 250px;
  box-shadow: 1px 1px 4px #aaa !important;
  border-radius: 5px;
  margin: auto;
  background-size: 100%;
  background-position: center !important;
  padding: 0px !important;
  background-color: #fff !important;
}

.ui-crud-form {
  max-width: 450px;
  width: 100%;
  margin: auto;
  margin-top: 5px;
}

.ui-crud-form h3 {
  text-align: center;
  margin-bottom: 40px;
}

.ui-preview-foto {
  margin-bottom: 4px !important;
}

.ui-preview-foto span {
  opacity: 0.4;
  width: 90vw;
  height: 55.5555vw;
  max-width: 450px;
  max-height: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ui-preview-foto:hover {
  opacity: 0.7 !important;
}

.ui-label-foto-partida {
  border-radius: 50px !important;
}

.ui-div-msg {
  margin: 20px 10px;
}

/* Página Partida */

.corpo-pagina-partida {
  padding: 110px 40px 20px 40px;
  background: #053256;
  /* background-image: url("/assets/imagens/champ.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: linear-gradient(to right, rgb(7, 4, 6), #053256);
  color: #fff;
  margin-top: -64px;
  position: relative;
}

.ui-img-pagina-partida {
  text-align: center;
}

.ui-img-pagina-partida img {
  background-color: rgb(255, 255, 255);
  background: linear-gradient(to right, rgb(204, 219, 207), rgb(255, 255, 255));
  border-radius: 6px;
  width: 100%;
  max-width: 450px;
  box-shadow: 1px 1px 4px #333;
}

.ui-pagina-partida-section-1 {
  padding-bottom: 50px !important;
}

.ui-pagina-partida-section-1 h1 {
  font-size: 29pt;
  font-weight: 900 !important;
  margin-top: 0 !important;
}

.ui-pagina-partida-section-1 h2 {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin: 0;
}

.ui-pagina-partida-section-1 h3 {
  margin-bottom: 0px;
  font-size: 12pt;
  font-weight: 300 !important;
  display: inline-block;
  padding-right: 30px;
}

.ui-pagina-partida-section-2 {
  padding: 40px 30px;
}

.ui-pagina-partida-section-2 p {
  font-weight: 300;
  font-size: 13pt;
}

.ui-section-2-svg {
  text-align: center;
  padding: 0px 100px;
}

.ui-section-2-svg svg {
  max-height: 100px;
  fill: #111 !important;
  margin-bottom: 10px;
}

.ui-pagina-partida-section-3 {
  padding: 70px 20px;
  background: linear-gradient(to right, rgb(7, 4, 6), #053256) !important;
  color: #fff;
}

.ui-pagina-partida-section-3 h2 {
  text-align: center;
}

.ui-pagina-partida-section-3 h3 {
  text-align: center;
  font-weight: 100;
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

/* Rodapé */

.ui-rodape {
  padding: 80px 30px;
  background: #053256;
  /* background-image: url("/assets/imagens/turmas-vip1.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: linear-gradient(to right, rgb(7, 4, 6), #053256);
  text-align: center;
  color: #fff;
}

.ui-rodape p {
  margin: 5px;
}

.ui-rodape a {
  color: #fff !important;
}

.ui-rodape i:hover {
  color: #fff !important;
  transition: 0.3s;
  transform: scale(1.2) !important;
}

.label-contador {
  font-size: 11px;
  color: #999;
  position: absolute;
  bottom: 53px;
  right: 8px;
}

/* body {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
} */

.ui-card-dashboard {
  margin: auto !important;
  text-align: center !important;
  padding: 20px !important;
  color: #053256 !important;
}

.ui-card-dashboard * {
  color: #053256 !important;
}

.ui-card-description-dashboard {
  padding-top: 20px;
}

/* .ui-card-foto {
  max-width: 100% !important;
  height: 0px !important;
} */

.ui-btn-1{
  box-sizing: border-box !important;
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
    /* background-color: #ee6f57 !important; */
    background-color: #394867 !important;
    border: 2px solid #616161 !important;
    border-radius: 0.6em !important;
    color: #FFF !important;
    cursor: pointer !important;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    margin: 20px !important;
    padding: 1.2em 2.8em !important;
    text-decoration: none !important;
    text-align: center !important;
    text-transform: uppercase !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700 !important;
    padding: 20px 90px !important;
    align-items: center !important;
    justify-content: center !important;
}
.ui-btn-1:hover, .ui-btn-1:focus{
  color: #fff !important;
  outline: 0 !important;
}

.ui-btn-theme-1{
  border-color: #000 !important;
  color: #fff;
  background-image: -webkit-linear-gradient(45deg, #26a69a 50%, transparent 50%);
  background-image: linear-gradient(45deg, #26a69a 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}

.ui-btn-theme-1:hover{
  background-position: 0;
}

.small{
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.ui-card-dashboard:hover{
  border: 1px solid #26a69a !important;
  color: #26a69a !important;
}

.extra{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ui-crud-form-container{
  position: relative !important;
  padding-bottom: 25px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ui-crud-form input::placeholder,
.ui-crud-form textarea::placeholder{
  color: #616161 !important;
}

.ui-crud-form input,
.ui-crud-form textarea{
  border: 0 !important;
  border-bottom: 2px solid #9e9e9e !important;
  outline: none !important;
  transition: .2s ease-in-out !important;
  box-sizing: border-box !important;
}

.ui-crud-form-input, .ui-crud-form-label{
  font-size: 1rem;
}

.ui-crud-form-label{
  position: absolute !important;
  top: 4px !important;
  left: 0 !important;
  right: 0 !important;
  color: #616161 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 1rem !important;
  cursor: text !important;
  transition: .2s ease-in-out !important;
  box-sizing: border-box !important;
}

.ui-crud-form-input:valid, .ui-crud-form input:focus{
  border-bottom: 2px solid #26a69a !important;
}
.ui-crud-form-input:valid, .ui-crud-form textarea:focus{
  border-bottom: 2px solid #26a69a !important;
}

.ui-crud-form-input:valid + .ui-crud-form-label,
.ui-crud-form-input:focus + .ui-crud-form-label{
  color: #26a69a !important;
  font-size: .8rem !important;
  top: -30px !important;
  pointer-events: none !important;
} 

.ui-crud-form-input:focus + label, 
.ui-crud-form-input:not(:placeholder-shown) + label{
  margin-top: 0 !important;

}

@media (max-width: 1030px){
  .ui-home-section-3{
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: center;
    background-attachment: center;
  }
}

@media(max-width: 700px){
  .ui-home-section-3{
    background-size: 120% 100%;
    background-position: center;
    background-attachment: center;
  }
}
@media(max-width: 600px){
  .ui-home-section-3{
    background-size:cover;
    background-position: center;
    background-attachment: center;
  }
}
@media(max-width: 500px){
  .ui-home-section-3{
    background-size: 160% 100%;
    background-position: center;
    background-attachment: center;
  }
}
@media(max-width: 400px){
  .ui-home-section-3{
    background-size: 170% 100%;
    background-position: center;
    background-attachment: center;
  }
}
@media(max-width: 300px){
  .ui-home-section-3{
    background-size: 190% 100%;
    background-position: center;
    background-attachment: center;
  }
}
@media(max-width: 200px){
  .ui-home-section-3{
    background-size: 240% 100%;
    background-position: center;
    background-attachment: center;
  }
}

@media (max-width: 767px) {
  .corpo-home {
    padding-left: 20px;
    padding-right: 20px;
  }

  .corpo-home h1 {
    font-size: 8.6vw;
  }

  @keyframes sobe-desce {
    from {
      top: -15px;
    }
    to {
      top: 30px;
    }
  }
  .logo-home {
    padding-left: 20px !important;
    margin-top: 40px !important;
  }
  .logo-home img {
    height: 82px;
    padding: 3px 16px;
  }
  .logo-dashboard {
    padding-left: 20px !important;
  }
  .menu-item-drawer {
    font-size: 11pt !important;
  }
  .ui-home-section-1 {
    padding: 0px 10px 70px 10px !important;
  }
  .ui-home-section-1 h1,
  .ui-home-section-1 h3 {
    text-align: center;
  }
  .ui-img-home {
    display: none !important;
  }
  .ui-form-contato {
    width: 92%;
  }
  .corpo-pagina-partida {
    padding: 85px 20px 20px 20px;
    margin-top: -50px;
  }
  .ui-pagina-partida-section-1 h3 {
    display: block;
  }
  .ui-section-2-svg {
    padding: 0px 20px;
  }
  .ui-home-section-4 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ui-div-section-4 {
    margin-bottom: 0px;
  }
  /* .ui-home-section-vip {
    background-position: -250px;
  } */
  /* Login */
  .ui-pagina-login {
    padding-top: 30px;
  }
  .ui-pagina-login .ui-login-logo img {
    width: 55%;
  }
}
